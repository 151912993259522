'use client'

import { BStockDevTools } from '@b-stock/bstock-next'

export default function DevTools({
  buildId = 'unspecified',
}: {
  buildId?: string
}) {
  return <BStockDevTools buildId={`home-portal@${buildId}`} />
}
