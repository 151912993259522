'use client'

import { createContext, useContext } from 'react'

import { useAuthState } from '@b-stock/bstock-next'
import { useAnalytics as useAnalyticsShared } from '@b-stock/bstock-react'

const SegmentWriteKeyContext = createContext<string | undefined>(undefined)

export function useAnalytics() {
  const segmentKey = useContext(SegmentWriteKeyContext)
  if (segmentKey === undefined) {
    throw new Error(
      'useAnalytics must be used within an AnalyticsProvider context'
    )
  }

  // TODO: we have not yet implemented auth state in this portal and we should
  // probably warn if we're not nested within an AuthProvider context, but for
  // now this code is harmless and accountId and personId will remain undefined
  const authState = useAuthState()
  const accountId = authState?.person.accountId
  const personId = authState?.person._id

  return useAnalyticsShared({
    accountId,
    personId,
    segmentKey,
  })
}

export const AnalyticsProvider = ({
  segmentWriteKey,
  children,
}: {
  segmentWriteKey: string
  children: React.ReactNode
}) => {
  return (
    <SegmentWriteKeyContext.Provider value={segmentWriteKey}>
      {children}
    </SegmentWriteKeyContext.Provider>
  )
}
