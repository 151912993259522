'use client'

import { ddLogger } from '@b-stock/bstock-react'

export default function DatadogClientLogger(
  props: Parameters<(typeof ddLogger)['startLogger']>[0]
) {
  // Only start the logger on the client side
  if (typeof window !== 'undefined') {
    ddLogger.startLogger(props)
  }
  return null
}
