import(/* webpackMode: "eager" */ "/home/node/app/node_modules/@b-stock/bstock-next/dist/components/BStockApp/BStockAppProviders.js");
;
import(/* webpackMode: "eager" */ "/home/node/app/node_modules/@tanstack/react-query-devtools/build/modern/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/home/node/app/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/home/node/app/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/home/node/app/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/home/node/app/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/home/node/app/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/home/node/app/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/home/node/app/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/home/node/app/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/home/node/app/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/home/node/app/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/home/node/app/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/home/node/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/home/node/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/home/node/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/node/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/node/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer","cssTransition"] */ "/home/node/app/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/home/node/app/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/home/node/app/src/components/DevTools.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/node/app/src/helpers/telemetry/DatadogClientLogger.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/node/app/src/helpers/telemetry/GoogleTagManager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AnalyticsProvider"] */ "/home/node/app/src/helpers/telemetry/SegmentAnalytics.tsx");
